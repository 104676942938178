import { createContext, useContext, useState } from "react";

export const AuthContext = createContext({
  token: null,
});

export const AuthIsSignedIn = ({ children }) => {
  const { token } = useContext(AuthContext);

  return token ? children : null;
};

export const AuthIsNotSignedIn = ({ children }) => {
  const { token } = useContext(AuthContext);
  return <>{!token ? children : null}</>;
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(localStorage.getItem("role"));

  console.log(token);

  const changeToken = (token, role) => {
    setToken(token);
    setRole(role);
    console.log("wws", token);
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
  };

  return (
    <AuthContext.Provider value={{ token, role, changeToken }}>
      {children}
    </AuthContext.Provider>
  );
};
