import { useState, useEffect } from 'react';
import { Table, Button, Input, message } from 'antd';
import { url } from "../ config";
import { CopyOutlined } from '@ant-design/icons';


const Keys = () => {
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [generateCount, setGenerateCount] = useState(1);

  const fetchKeys = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${url}/keys`);
      const data = await response.json();
      setKeys(data);
    } catch (error) {
      message.error('Не удалось загрузить ключи');
    } finally {
      setLoading(false);
    }
  };

  const generateKeys = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${url}/keys/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ count: generateCount }),
      });
      if (response.ok) {
        message.success(`Сгенерировано ${generateCount} новых ключа(ей)`);
        fetchKeys();
      } else {
        throw new Error('Не удалось сгенерировать ключи');
      }
    } catch (error) {
      message.error('Не удалось сгенерировать ключи');
    } finally {
      setLoading(false);
    }
  };

  const disableKey = async (key) => {
    try {
      const response = await fetch(`${url}/keys/disable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ key }),
      });
      if (response.ok) {
        message.success('Ключ отключен успешно');
        fetchKeys();
      } else {
        throw new Error('Не удалось отключить ключ');
      }
    } catch (error) {
      message.error('Не удалось отключить ключ');
    }
  };

  useEffect(() => {
    fetchKeys();
  }, []);

  // Split keys into categories
  const activeKeys = keys.filter(key => key.is_active && !key.is_used);
  const usedKeys = keys.filter(key => key.is_used);
  const disabledKeys = keys.filter(key => !key.is_active);

  const columns = [
    {
      title: 'API Ключ',
      dataIndex: 'key',
      key: 'key',
      render: (text) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {text}
          <Button
            type="text"
            icon={<CopyOutlined />}
            onClick={() => {
              navigator.clipboard.writeText(text);
              message.success('API ключ скопирован');
            }}
          />
        </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (active) => (
        <span style={{ color: active ? 'green' : 'red' }}>
          {active ? 'Активен' : 'Отключен'}
        </span>
      ),
    },
    {
      title: 'Использован',
      dataIndex: 'is_used',
      key: 'is_used',
      render: (used) => (
        <span style={{ color: used ? 'orange' : 'green' }}>
          {used ? 'Да' : 'Нет'}
        </span>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        <Button 
          danger 
          disabled={!record.is_active}
          onClick={() => disableKey(record.key)}
        >
          Отключить
        </Button>
      ),
    },
  ];

  const simpleColumns = columns.slice(0, 3); // Exclude Actions column

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '24px', display: 'flex', gap: '16px' }}>
        <Input
          type="number"
          min={1}
          value={generateCount}
          onChange={(e) => setGenerateCount(Number(e.target.value))}
          style={{ width: '200px' }}
          placeholder="Количество ключей"
        />
        <Button type="primary" onClick={generateKeys}>
          Сгенерировать ключи
        </Button>
      </div>

      <h2>Активные ключи</h2>
      <Table
        columns={columns}
        dataSource={activeKeys}
        loading={loading}
        rowKey="key"
        pagination={{ pageSize: 5 }}
      />

      <h2 style={{ marginTop: '24px' }}>Использованные ключи</h2>
      <Table
        columns={simpleColumns}
        dataSource={usedKeys}
        loading={loading}
        rowKey="key"
        pagination={{ pageSize: 5 }}
      />

      <h2 style={{ marginTop: '24px' }}>Отключенные ключи</h2>
      <Table
        columns={simpleColumns}
        dataSource={disabledKeys}
        loading={loading}
        rowKey="key"
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default Keys; 